<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    新增代理商
                </div>
            </template>
            <div class="content">
                <el-form :model="merchantinfo" ref="merchantinfo" label-width="150px" size="small" :inline="true">
                    <el-card>
                        <template #header>
                            <div style="display:flex;justify-content:flex-start;align-items:center">
                                基本信息
                            </div>
                        </template>
                        <div class="content-block">
                            <el-form-item label="运营/客服姓名:" prop="MerchantName"   :rules="{required:'true',trigger:'blur',message:'请填写联系人姓名'}">
                                <el-input v-model="merchantinfo.MerchantName" disabled ></el-input>
                            </el-form-item>
                            <el-form-item label="联系人电话:" prop="MerchantMobile"  :rules="{required:'true',trigger:'blur',message:'请填写联系人电话'}">
                                <el-input v-model="merchantinfo.MerchantMobile" disabled></el-input>
                            </el-form-item>
                            <br>
                            <el-form-item label="商户名称:" prop="Merchant"  :rules="{required:'true',trigger:'blur',message:'请填写商户名称'}">
                                <el-input v-model="merchantinfo.Merchant"  style="width: 300px"></el-input>
                            </el-form-item>
                            
                            <el-form-item label="协议商户名称:" prop="AgreementName"  :rules="{required:'true',trigger:'blur',message:'请填写协议商户名称'}">
                                <el-input style="width: 300px;" v-model="merchantinfo.AgreementName" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="结算比例(%):" prop="Rate">
                                <el-input-number style="width: 80px;" v-model="merchantinfo.Rate" :controls='false'></el-input-number>
                            </el-form-item>
                            <el-form-item label="实际经营地址:" prop="Address"  :rules="{required:'true',trigger:'blur',message:'请填写实际经营地址'}">
                                <el-input style="width: 555px;" disabled v-model="merchantinfo.Address"></el-input>
                            </el-form-item>
                            <br/>
                            <el-form-item label="代理商:" prop="agentname" :rules="{required:'true',trigger:'blur',message:'请填写代理商'}">
                                 <el-input style="width: 150px;" v-model="merchantinfo.agentname"></el-input>
                            </el-form-item>
                            <el-form-item label="电话" prop="agentphone" :rules="{required:'true',trigger:'blur',message:'请填写代理商手机号'}">
                                 <el-input style="width: 250px;" v-model="merchantinfo.agentphone"></el-input>
                            </el-form-item>
                            <el-form-item label="所属城市" prop="agentcity" :rules="{required:'true',trigger:'blur',message:'请填写代理商城市'}">
                                 <el-input style="width: 250px;" v-model="merchantinfo.agentcity"></el-input>
                            </el-form-item>
                            <br/>
                            <el-form-item label="身份证正面照片：" prop="front_id">
                                <el-upload
                                    v-model:file-list="FileList"
                                    :action="upload_url"
                                    list-type="picture-card"
                                    :on-preview="Preview"
                                    :on-remove="RemovePic"
                                    :on-success="UploadSuccess"
                                    :before-upload="beforeAvatarUpload"
                                >
                                    上传图片
                                </el-upload>

                                <el-dialog v-model="dialogVisible">
                                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                                </el-dialog>   
                            </el-form-item>
                            <el-form-item label="身份证反面照片：" prop="back_id">
                                <el-upload
                                    v-model:file-list="FileList1"
                                    :action="upload_url"
                                    list-type="picture-card"
                                    :on-preview="Preview1"
                                    :on-remove="RemovePic1"
                                    :on-success="UploadSuccess1"
                                    :before-upload="beforeAvatarUpload1"
                                >
                                    上传图片
                                </el-upload>

                                <el-dialog v-model="dialogVisible1">
                                    <img w-full :src="dialogImageUrl1" alt="Preview Image" />
                                </el-dialog>
                            </el-form-item>
                        </div>
                    </el-card>

                    <el-card>
                        <template #header>
                            归还信息
                        </template>
                        <div class="content-block">
                            <div v-for="item,index in merchantinfo.returninfo" :key="item">
                                <el-form-item label="联系人姓名:" required>
                                    <el-input v-model="item.Contact" disabled></el-input>
                                </el-form-item>
                                <el-form-item label="联系人电话:" required>
                                    <el-input v-model="item.Mobile" disabled></el-input>
                                </el-form-item>
                                <el-form-item label="发货省市" required>
                                    <el-input v-model="item.sname" placeholder="如广东深圳"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-radio label="1" v-model="item.IsDefault" @change="Confirm(index)">设为默认</el-radio>
                                </el-form-item>
                                <el-form-item label="归还地址:" required>
                                    <el-input style="width: 450px" disabled v-model="item.ReturnAddress"></el-input>
                                </el-form-item>
                                <el-button type="danger" size="small" @click="Del(index)" v-if="index > 0">删除</el-button>
                            </div>
                            
                        </div>
                    </el-card>
                    <el-card>
                        <template #header>
                            其他信息
                        </template>
                        <div class="content-block" style="display:flex;flex-direction:column">
                            <el-form-item label="是否支持租赁宝">
                                <div class="radioblock">
                                    <el-radio-group v-model="merchantinfo.ChainEnable" @change="ToggleEign" >
                                        <el-radio-button label="0">否</el-radio-button>
                                        <el-radio-button label="1">是</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                            <el-form-item v-if="isInsurance" label="是否支持保险">
                                <div class="radioblock">
                                    <el-radio-group v-model="merchantinfo.Insurance"  >
                                        <el-radio-button label="0">否</el-radio-button>
                                        <el-radio-button label="1">是</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                        </div>
                    </el-card>
                </el-form>
                <div class="footer">
                    <el-button type="primary" size="small" @click="SubmitForm('merchantinfo')">保存</el-button>
                    <el-button type="warning" size="small" @click="ResetForm('merchantinfo')">重置</el-button>
                    <el-button size="small" @click="Back()">返回</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>
<style scoped>
.el-upload--picture-card {
    --el-upload-picture-card-size: 148px;
    background-color: var(--el-fill-color-lighter);
    border: 1px dashed ;
    border-radius: 6px;
    box-sizing: border-box;
    width: var(--el-upload-picture-card-size);
    height: var(--el-upload-picture-card-size);
    cursor: pointer;
    vertical-align: top;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.el-card__header {
    padding: 10px 20px;
}
.content .el-card {
    margin-bottom: 20px;
}
.radioblock {
    display: flex;
    flex-direction: column;
}
</style>
<script>
import qs from 'qs';
import constant from "@/constant"
import { ref } from 'vue';
export default {
    
    data(){
        return {
            upload_url:constant.upload_temp_img_url,
            FileList:[],
            FileList1:[],
            FileList2:[],
            FileList3:[],
            FileList4:[],
            url:"",
            dialogImageUrl1:"",
            dialogImageUrl2:"",
            dialogImageUrl3:"",
            dialogImageUrl4:"",
            dialogImageUrl:"",
            dialogVisible:false,
            dialogVisible1:false,
            dialogVisible2:false,
            dialogVisible3:false,
            dialogVisible4:false,
            keyword:'',
            status:'',
            shopstatus:'',
            categorys:[],
            props:{
                label:'label',
                value:'value',
                disabled:'Disabled',
                multiple: true
            },
            isInsurance:ref(false),
            merchantinfo:{
                isagent:1,    
                ID:'',
                AgreementName:"深圳市闲牛科技有限公司",
                MerchantName:'陈炫展',
                MerchantMobile:'13828873430',
                Merchant:'',
                Address:'深圳市龙岗区坂田街道万科城社区居里夫人大道神舟电脑大厦6F6楼6A08',
                Rate:92,
                agentname:"",
                agentphone:"",
                agentcity:"",
                EsignEnable:"0",
                RiskEnable:"1",
                returninfo:[{
                    ID:'',
                    Contact:'闲牛租',
                    Mobile:'13828873430',
                    ReturnAddress:'深圳市龙岗区坂田街道万科城社区居里夫人大道神舟电脑大厦6F6楼6A08',
                    sname:'',
                    IsDefault:'1',
                }],
                esigncategory:[],
                riskcategory:[],
                imgFile:[],
                imgFile1:[],
                imgFile2:[],
                imgFile3:[],
                imgFile4:[],
                CompanyName:"",
                CreditCode:"",
                RegisteredAddress:"",
                LegalName:"",
                LegalTel:"",
                LegalId:"",
                LegalEmail:"",
                ContactMail:"",
                LeaseScope:"",
                CompanyType : '1',
                SupplierID:'',
                AccessKeyID:'',
                AccessKeySecret:'',
                ChainEnable:'0',
                Insurance:"0",
                LeaseCorpID:'',
                LeaseCorpName:'',
                LeaseCorpOwner:'',
                AlipayUID:'',
            },
        }
    },
    methods:{
        beforeAvatarUpload(file){
            console.log(file)
            let array = ["image/jpeg","image/png",'image/jpg']
            
            if(array.indexOf(file.type) == -1){
                this.$message.error("请上传图片类型的文件")
                return false
            }else if(file.size / 1024 / 1024 > 2){
                this.$message.error("文件大小不能大于2M")
                return false
            }
        },
        beforeAvatarUpload1(file){
            console.log(file)
            let array = ["image/jpeg","image/png",'image/jpg']
            
            if(array.indexOf(file.type) == -1){
                this.$message.error("请上传图片类型的文件")
                return false
            }else if(file.size / 1024 / 1024 > 2){
                this.$message.error("文件大小不能大于2M")
                return false
            }
        },
        UploadSuccess(response,file,filelist){
            console.log(file);
            console.log("list");
            console.log(filelist);
            console.log(response);
            if(response.error == '0'){
                //this.pdtinfo.PdtImg.push(response.url);
                this.merchantinfo.imgFile = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.merchantinfo.imgFile.push(obj);
                }
                console.log("mylist");
                console.log(this.merchantinfo.imgFile);
            }
        },
        RemovePic(file,filelist){
            console.log(file);
            console.log(filelist);
            this.merchantinfo.imgFile = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = filelist[i]['url'];
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.merchantinfo.imgFile.push(obj);
                }
        },
        Preview(file) {
            console.log(file);
            if(file.response){
                if(file.response.error == 0){
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else{
                this.dialogImageUrl = file.url;
            }
            
            this.dialogVisible = true;
        },
        UploadSuccess1(response,file,filelist){
            console.log(file);
            console.log("list");
            console.log(filelist);
            console.log(response);
            if(response.error == '0'){
                //this.pdtinfo.PdtImg.push(response.url);
                this.merchantinfo.imgFile1 = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.merchantinfo.imgFile1.push(obj);
                }
                console.log("mylist");
                console.log(this.merchantinfo.imgFile1);
            }
        },
        RemovePic1(file,filelist){
            console.log(file);
            console.log(filelist);
            this.merchantinfo.imgFile1 = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = filelist[i]['url'];
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.merchantinfo.imgFile1.push(obj);
                }
        },
        Preview1(file) {
            console.log(file);
            if(file.response){
                if(file.response.error == 0){
                    this.dialogImageUrl1 = file.response.url;
                    console.log(this.dialogImageUrl1);
                }
            }
            else{
                this.dialogImageUrl1 = file.url;
            }
            
            this.dialogVisible1 = true;
        },
        Confirm(index){
            console.log(this.merchantinfo.returninfo);
            for(let i = 0;i<this.merchantinfo.returninfo.length;i++){
                let curobj = this.merchantinfo.returninfo[i];
                if(i != index)
                    curobj.IsDefault = '0';
            }
        },
        ResetForm(name){
            this.$refs[name].resetFields();
            this.merchantinfo.returninfo = [];
            this.merchantinfo.returninfo.push({
                ID:'',
                Contact:'',
                Mobile:'',
                ReturnAddress:'',
                sname:'',
                IsDefault:'1',
            });
        },
        SubmitForm(name){
            this.$refs[name].validate((valid)=>{
                if(valid){
                    if(this.merchantinfo.esign == 1){
                        console.log(this.merchantinfo.esigncategory);
                        if(this.merchantinfo.esigncategory.length <= 0){
                            this.$message.error("请选择需要e签宝的分类");
                            return false;
                        }
                    }
                    if(!("imgFile" in this.merchantinfo)){
                        this.$message.error("请上传身份证正面照");
                        return false;
                    }
                    
                    if(!("imgFile1" in this.merchantinfo)){
                        this.$message.error("请上传身份证背面照");
                        return false;
                    }
                    // var myReg=/^[a-zA-Z0-9]+((_|-|\.)[a-zA-Z0-9]+)*@([a-zA-Z0-9]+(-|\.))+[a-zA-Z]{2,5}$/;
                    // if(!myReg.test(this.merchantinfo.LegalEmail)){
                    //     this.$message.error("邮箱格式错误")
                    //     return false
                    // }
                    // const regex = /^1[3456789]\d{9}$/;
                    
                    // if(!regex.test(this.merchantinfo.LegalTel)){
                    //     this.$message.error('手机号格式错误')
                    //     return false
                    // }
                    // if(!Number.isInteger(Number(this.merchantinfo.LegalId))){
                    //     this.$message.error("请输入出数字形式的身份证号")
                    //     return false
                    // }
                    
                    /**暂时统一使用闲牛的风控配置 */
                    /*
                    if(this.merchantinfo.risk == 1){
                        if(this.merchantinfo.riskcategory.length <= 0){
                            this.$message.error("请选择需要风控的分类");
                            return false;
                        }
                    }*/
                    var flag = true;
                    for(let i = 0;i<this.merchantinfo.returninfo.length;i++){
                        let curitem = this.merchantinfo.returninfo[i];
                        if(!curitem.Contact || !curitem.Mobile || !curitem.ReturnAddress || !curitem.sname){
                            this.$message.error("请完善归还信息");
                            flag = false;
                        }
                    }
                    if(flag){
                        //提交表单
                        console.log(this.merchantinfo);
                        if(this.merchantinfo.ChainEnable == 1){
                            this.axios.get(constant.merchant_user,{
                                headers:{
                                    'Content-Type': 'application/json'
                                },
                                params:{
                                    merchant_user:JSON.stringify(this.merchantinfo)
                                }
                            }).then((response)=>{
                                console.log(response.data)
                            })  
                        }

                        this.axios.post(constant.saveagent,qs.stringify(this.merchantinfo),{
                            headers:{
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }).then((response)=>{
                            console.log(response.data);
                            if(response.data == 'OK'){
                                this.$message.success("操作成功");
                                this.$router.go(-1);
                            }
                        })
                    }
                }
            });
        },
        Add(){
            this.merchantinfo.returninfo.push({
                ID:'',
                Contact:'',
                Mobile:'',
                ReturnAddress:'',
                sname:'',
                IsDefault:false,
            });
        },
        Del(index){
            if(!index)
                return false;
            this.merchantinfo.returninfo.splice(index,1);
        },
        ToggleEign(val){
            console.log(val);
            if(val == 1){
                this.isInsurance = true
            }else{
                this.isInsurance = false
            }
        },
        ToggleRisk(val){
            console.log(val);
        },
        Back(){
            this.$router.push({path:'/merchantlist',query:{keyword:this.keyword,status:this.status,shopstatus:this.shopstatus}});
        }
    },
    created:function(){
        let id = this.$route.query.id;
        console.log(id);
        this.keyword = this.$route.query.keyword;
        this.status = this.$route.query.status;
        this.shopstatus = this.$route.query.shopstatus;
        if(id){
            this.axios.get(constant.getmerchant,{
                headers:{
                    "Content-Type":'application/json'
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.merchantinfo = response.data;
                if(response.data.ChainEnable == 1){
                    this.isInsurance = true
                }
                this.url = response.data.License
            });
        }
        // this.axios.get(constant.get_cascader_url,{
        //     headers:{
        //         'Content-Type': 'application/json'
        //     },
        // }).then((response)=>{
        //     console.log(response.data);
        //     this.categorys = response.data;
        // });
    }
}
</script>